import { useState } from 'react';
import styled from 'styled-components';
import { grayScales } from 'styles/variables';

const HamburgerButtonStyled = styled.div`
  .hamburger-button,
  .hamburger-button span {
    box-sizing: border-box;
    display: inline-block;
    transition: all 0.4s;
  }

  .hamburger-button {
    appearance: none;
    background: none;
    border: none;
    cursor: pointer;
    height: 21px;
    position: relative;
    width: 24px;

    &.active {
      span:nth-of-type(1) {
        transform: translateY(8px) rotate(-315deg);
      }

      span:nth-of-type(2) {
        opacity: 0;
      }

      span:nth-of-type(3) {
        transform: translateY(-10px) rotate(315deg);
      }
    }

    span {
      background-color: ${grayScales.gs900};
      height: 3px;
      left: 0;
      position: absolute;
      width: 100%;

      &:nth-of-type(1) {
        top: 0;
      }

      &:nth-of-type(2) {
        top: 9px;
      }

      &:nth-of-type(3) {
        bottom: 0;
      }
    }
  }
`;

interface HamburgerButtonInterface {
  didClickButton?: (opened: boolean) => void;
};

const HamburgerButton = (props: HamburgerButtonInterface) => {
  const [opened, setOpened] = useState(false);

  return (
    <HamburgerButtonStyled>
      <button
        className={['hamburger-button', opened ? 'active' : ''].join(' ')}
        onClick={() => {
          setOpened(!opened);
          props.didClickButton?.(!opened)
        }}
      >
        <span></span>
        <span></span>
        <span></span>
      </button>
    </HamburgerButtonStyled>
  )
}

export default HamburgerButton;
