import BasicMenuItem from 'components/atoms/basic_menu/BasicMenuItem';
import { getHeaderMenuState } from 'contexts/HeaderMenuContext';
import { useRouter } from 'next/router';
import { useEffect, useMemo, useState } from 'react';
import { BiChevronDown } from 'react-icons/bi';
import styled from 'styled-components';
import { colors, grayScales, zIndexes } from 'styles/variables';

const HeaderBasicMenuStyled = styled.div`
    .header-basic-menu {
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;

      &__thumbnail {
        display: block;
        position: relative;
        vertical-align: middle;
        width: 40px;
        height: 40px;
        border-radius: 50%;
      }

      &__down {
        fill: ${grayScales.gs600};
      }

      &__contents {
        position: absolute;
        top: 70px;
        right: 40px;
        display: none;
        background-color: ${colors.white};
        box-shadow: 0 2px 4px 0 rgb(0 0 0 / 10%);
        z-index: ${zIndexes.basicMenu};
      }

      &:hover {
        .header-basic-menu__down {
          fill: ${grayScales.gs900};
        }
      }
    }

    .opened-basic-menu {
      display: inline-block;
    }

    ol,
    ul {
      list-style: none;
      margin: 0;
      padding: 0;
    }
  `,
  HeaderBasicMenu: React.FC = () => {
    const { headerMenu } = getHeaderMenuState(),
      router = useRouter(),
      [opened, setOpened] = useState(false),
      basicMenuItemList = useMemo(() => {
        if (headerMenu === null) {
          return [];
        }

        return headerMenu.basicMenu.map((item, i) => <BasicMenuItem key={i} name={item.name} path={item.path} />);
      }, [headerMenu]),
      thumbnailImageUrl = useMemo(() => {
        if (headerMenu === null) {
          return '';
        }

        return headerMenu.thumbnailImageUrl;
      }, [headerMenu]);

    useEffect(() => {
      const closeMenu = () => {
        setOpened(false);
      }

      router.events.on('routeChangeStart', closeMenu);

      return () => {
        router.events.off('routeChangeStart', closeMenu);
      }
    }, []);

    return (
      <HeaderBasicMenuStyled>
        <div className='header-basic-menu' onClick={() => (opened ? setOpened(false) : setOpened(true))}>
          <img src={thumbnailImageUrl} className='header-basic-menu__thumbnail' alt='channel_icon' />
          <BiChevronDown className='header-basic-menu__down' size={20} />
          <ul className={['header-basic-menu__contents', opened ? 'opened-basic-menu' : ''].join(' ')}>{basicMenuItemList}</ul>
        </div>
      </HeaderBasicMenuStyled>
    );
  };

export default HeaderBasicMenu;
