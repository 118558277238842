import MediumItem from 'components/atoms/mobile_menu/MediumItem';
import Link from 'next/link';
import { useEffect, useState } from 'react';
import { BiChevronDown } from 'react-icons/bi';
import styled from 'styled-components';
import { fontSize } from 'styles/mixins';
import { colors, grayScales } from 'styles/variables';
import { HeaderMenuItemType } from 'types/headerMenuTypes';

const MajorItemStyled = styled.div`
  width: 100%;

  .major-item {
    &__major {
      align-items: center;
      background-color: ${colors.white};
      border-bottom: 1px solid ${grayScales.gs200};
      cursor: pointer;
      color: ${grayScales.gs600};
      display: flex;
      justify-content: space-between;
      font-size: 12px;
      height: 45px;
      padding: 0 20px;

      a {
        text-decoration: none;
        color: ${grayScales.gs600};
      }

      &.active {
        background-color: ${grayScales.gs200};
        color: ${grayScales.gs900};
      }

      &--name {
        align-items: center;
        display: flex;
        height: 100%;
        width: 100%;
      }

      &--button {
        cursor: pointer;
        ${fontSize(25)}

        &.close {
          transform: rotate(-90deg);
        }
      }
    }

    &__medium {
      overflow: hidden;
    }
  }

  @keyframes showMediumList {
    0% {
      margin-top: -1000px;
    }

    100% {
      margin-top: 0;
    }
  }

  @keyframes hideMediumList {
    0% {
      margin-top: 0;
    }

    100% {
      margin-top: -1000px;
    }
  }

  .show-medium-list {
    animation-name: showMediumList;
    animation-duration: 0.3s;
    animation-timing-function: ease;
    animation-fill-mode: forwards;
  }

  .hide-medium-list {
    animation-name: hideMediumList;
    animation-duration: 1.2s;
    animation-timing-function: ease;
    animation-fill-mode: forwards;
  }

  .spacer {
    padding-top: 10px;
  }
`;

type MajorItemType = HeaderMenuItemType & {
  currentName: string;
  // eslint-disable-next-line no-unused-vars
  setCurrentName: (arg: string) => void;
};

const MajorItem = (props: MajorItemType) => {
  const [opened, setOpened] = useState(false);

  useEffect(() => {
    if (props.currentName !== props.name) {
      setOpened(false);
    }
  }, [props.currentName]);

  const clickEvent = () => {
    if (opened) {
      setOpened(false);
    } else {
      setOpened(true)
    }
    props.setCurrentName(props.name);
  };

  return (
    <MajorItemStyled>
      <div className='major-item'>
        <div className={['major-item__major', opened ? 'active' : ''].join(' ')} onClick={() => clickEvent()}>
          {props.path === ''
            ? (<div className='major-item__major--name'>{props.name}</div>)
            : (
              <Link href={props.path} passHref>
                <div className='major-item__major--name'>{props.name}</div>
              </Link>
            )
          }
          {props.data.length < 1 ? (
            ''
          ) : (
            <div className={['major-item__major--button', opened ? '' : 'close'].join(' ')}>
              {props.data.length < 1 ? '' : <BiChevronDown />}
            </div>
          )}
        </div>
        <div className={'major-item__medium'}>
          {props.data.length < 1 ? '' : <div className='spacer'></div>}
          <div className={opened ? 'show-medium-list' : 'hide-medium-list'}>
            {props.data.map((item, i) => (
              <MediumItem key={i} name={item.name} path={item.path} />
            ))}
          </div>
        </div>
      </div>
    </MajorItemStyled>
  );
};

export default MajorItem;
