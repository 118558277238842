import MenuItem, { MenuItemInterface } from 'components/atoms/header_menu/MenuItem';
import LogoCreasHeader from 'components/atoms/logo/LogoCreasHeader';
import HamburgerButton from 'components/atoms/mobile_menu/HamburgerButton';
import HeaderBasicMenu from 'components/shareds/menu/HeaderBasicMenu';
import HeaderSubMenu from 'components/shareds/menu/HeaderSubMenu';
import HeaderMobileMenu from 'components/shareds/mobile_menu/HeaderMobileMenu';
import { getHeaderMenuState } from 'contexts/HeaderMenuContext';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { mediaDown } from 'styles/mixins';
import { colors, heights, zIndexes } from 'styles/variables';
import { HeaderMenuItemType, HeaderSubMenuItemType } from 'types/headerMenuTypes';

const HeaderMenuStyled = styled.div`
    .header-menu {
      align-items: center;
      box-shadow: 0 0 4px 0 rgb(0 0 0 / 10%);
      display: flex;
      height: ${heights.headerMenu};
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      z-index: ${zIndexes.menu};

      &--prod {
        background-color: ${colors.white};
      }

      &--stg {
        background-color: ${colors.yellow};
      }

      &__main {
        align-items: center;
        display: flex;
        justify-content: space-between;
        width: 100%;

        &--logo {
          ${mediaDown(
    'desktop',
    css`
              padding: 0 20px;
            `
  )}

          padding: 0 80px;
        }

        &--menu {
          align-items: center;
          display: flex;
          padding: 0 20px;
        }

        &--mobile {
          padding: 0 20px;
        }
      }

      &__mobile {
        height: calc(100% - ${heights.headerMenu});
        position: fixed;
        top: ${heights.headerMenu};
        width: 100%;
        z-index: ${zIndexes.mobileMenu};
      }
    }

    .responsive-desktop {
      ${mediaDown(
    'desktop',
    css`
          display: none;
        `
  )}
    }

    .responsive-mobile {
      ${mediaDown(
    'desktop',
    css`
          display: inline-block;
        `
  )}

      display: none;
    }

    @keyframes showMobileMenu {
      0% {
        left: 100%;
      }
      100% {
        left: 0;
      }
    }

    @keyframes hideMobileMenu {
      0% {
        left: 0;
      }
      100% {
        left: 100%;
      }
    }

    .show-mobile-menu {
      animation-duration: 0.2s;
      animation-fill-mode: forwards;
      animation-name: showMobileMenu;
      animation-timing-function: ease;
    }

    .hide-mobile-menu {
      animation-duration: 0.2s;
      animation-fill-mode: forwards;
      animation-name: hideMobileMenu;
      animation-timing-function: ease;
    }
  `,
  HeaderMenu = () => {
    const { headerMenu } = getHeaderMenuState(),
      router = useRouter(),
      [opened, setOpened] = useState<boolean>(false),
      [subMenuOpened, setSubMenuOpened] = useState<boolean>(false),
      [menu, setMenu] = useState<HeaderMenuItemType[]>([]),
      [subMenuData, setSubMenuData] = useState<HeaderSubMenuItemType[]>([]),
      [currentMenuName, setCurrentMenuName] = useState<string>(''),
      isStaging = process.env.NEXT_PUBLIC_APP_ENV === 'staging';

    useEffect(() => {
      setMenu(headerMenu.menu)
    }, [headerMenu]);

    useEffect(() => {
      const closeMenu = () => {
        setOpened(false);
        setSubMenuOpened(false);
      }

      router.events.on('routeChangeStart', closeMenu);

      return () => {
        router.events.off('routeChangeStart', closeMenu);
      }
    }, [])

    return (
      <HeaderMenuStyled>
        <div className={['header-menu', isStaging ? 'header-menu--stg' : 'header-menu--prod'].join(' ')}>
          <nav className='header-menu__main'>
            <div className='header-menu__main--logo'>
              <LogoCreasHeader />
            </div>
            <div className={['header-menu__main--mobile', 'responsive-mobile'].join(' ')}>
              <HamburgerButton
                didClickButton={(isOpened) => setOpened(isOpened)}
              />
            </div>
            <div className={['header-menu__main--menu', 'responsive-desktop'].join(' ')}>
              {menu.map((item, i) => (
                <MenuItem
                  key={i}
                  didClickMenuItem={(menuItem: MenuItemInterface) => {
                    if (item.data.length < 1) {
                      setSubMenuOpened(false);
                    } else if (currentMenuName === '') {
                      setSubMenuOpened(true);
                    } else if (menuItem.name === currentMenuName) {
                      setSubMenuOpened(!subMenuOpened);
                    } else {
                      setSubMenuOpened(true);
                    }

                    setSubMenuData(item.data);
                    setCurrentMenuName(menuItem.name);
                  }}
                  opened={currentMenuName === item.name}
                  name={item.name}
                  path={item.path}
                  target={item.target}
                />
              ))}
              <HeaderBasicMenu />
            </div>
          </nav>
        </div>
        <div className={['header-menu__mobile', 'responsive-mobile', opened ? 'show-mobile-menu' : 'hide-mobile-menu'].join(' ')}>
          <HeaderMobileMenu />
        </div>
        <HeaderSubMenu subMenuOpened={subMenuOpened} subMenuData={subMenuData} />
      </HeaderMenuStyled>
    );
  };

export default HeaderMenu;
