import { useRouter } from 'next/router';
import styled from 'styled-components';
import { colors, grayScales } from 'styles/variables';

interface MediumItemInterface {
  name: string;
  path?: string;
  didClickMediumItem?: (mediumItem: MediumItemInterface) => void;
}

const MediumItemStyled = styled.div`
    &.medium-item {
      align-items: center;
      background-color: ${colors.white};
      border-left: 2px solid ${grayScales.gs200};
      color: ${grayScales.gs600};
      cursor: pointer;
      display: flex;
      font-size: 12px;
      height: 45px;
      margin-left: 30px;
      padding-left: 20px;
      width: 100%;

      &:hover {
        border-left: 2px solid ${colors.primary};
        color: ${colors.primary};
      }
    }
  `,
  MediumItem = (props: MediumItemInterface) => {
    const router = useRouter()

    return (
      <MediumItemStyled
        className='medium-item'
        onClick={() => {
          if (props.path) {
            router.push(props.path)
          }
          props.didClickMediumItem?.(props)
        }}
      >
        {props.name}
      </MediumItemStyled>
    )
  }

export default MediumItem;
