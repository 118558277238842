import MajorItem from 'components/shareds/mobile_menu/MajorItem';
import { getHeaderMenuState } from 'contexts/HeaderMenuContext';
import { useState } from 'react';
import styled from 'styled-components';
import { colors } from 'styles/variables';

const HeaderMobileMenuStyled = styled.div`
    height: 100%;

    .header-mobile-menu {
      background-color: ${colors.white};
      height: 100%;
      overflow: scroll;
      position: relative;
      width: 100%;
    }
  `,
  HeaderMobileMenu = () => {
    const { headerMenu } = getHeaderMenuState(),
      [currentName, setCurrentName] = useState('');

    if (headerMenu === null) {
      return <></>;
    }

    return (
      <HeaderMobileMenuStyled>
        <nav className='header-mobile-menu'>
          {headerMenu.menu
            .concat(
              headerMenu.basicMenu.map((item) => {
                if (item.data === undefined) {
                  item.data = [];
                }
                return item;
              })
            )
            .map((item, key: number) => (
              <MajorItem
                key={key}
                currentName={currentName}
                setCurrentName={setCurrentName}
                data={item.data}
                name={item.name}
                path={item.path}
              />
            ))}
        </nav>
      </HeaderMobileMenuStyled>
    );
  };

export default HeaderMobileMenu;
