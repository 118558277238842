import { useRouter } from 'next/router';
import styled from 'styled-components';
import { grayScales } from 'styles/variables';

const MenuItemStyled = styled.div`
  &.menu-item {
    color: ${grayScales.gs600};
    cursor: pointer;
    display: inline-block;
    font-size: 12px;
    height: 100%;
    line-height: 64px;
    padding: 0 24px;
    position: relative;
    white-space: nowrap;

    a {
      color: ${grayScales.gs600};
      text-decoration: none;
    }

    & a:hover,
    &:hover {
      color: ${grayScales.gs900};
    }
  }

  &.menu-item-opened {
    &:after {
      color: #f0f0f0;
      content: '▲';
      font-family: Material Icons, sans-serif;
      font-size: 14px;
      left: calc(50% - 7px);
      position: absolute;
      top: 28px;
    }
  }
`;

export interface MenuItemInterface {
  name: string;
  opened: boolean;
  path?: string;
  target?: string;
  didClickMenuItem?: (menuItem: MenuItemInterface) => void
};

const MenuItem = (props: MenuItemInterface) => {
  const router = useRouter()

  return (
    <MenuItemStyled
      className={[
        'menu-item',
        props.opened ? 'menu-item-opened' : ''
      ].join(' ')}
      onClick={() => {
        if (props.target) {
          window.open(props.path, props.target)
        }
        else if (props.path) {
          router.push(props.path)
        }
        props.didClickMenuItem?.(props)
      }}
    >
      {props.name}
    </MenuItemStyled>
  );
};

export default MenuItem;
