import { useRouter } from 'next/router';
import styled from 'styled-components';
import { fontSize } from 'styles/mixins';
import { grayScales } from 'styles/variables';

const BasicMenuItemStyled = styled.li`
  ${fontSize(14)}
  color: ${grayScales.gs600};
  cursor: pointer;
  font-weight: 400;
  list-style: none;
  margin: 1rem 0;
  padding: 16px;
  text-decoration: none;
  width: 150px;

  &:hover {
    background-color: ${grayScales.gs200};
    color: ${grayScales.gs900};
  }
`;

interface BasicMenuItemInterface {
  name: string;
  path?: string;
  didClickBasicMenuItem?: (props: BasicMenuItemInterface) => void;
};

const BasicMenuItem = (props: BasicMenuItemInterface) => {
  const router = useRouter();

  return (
    <BasicMenuItemStyled
      className='basic-menu-item'
      onClick={() => {
        if (props.path) {
          router.push(props.path)
        }
        props.didClickBasicMenuItem?.(props)
      }}
    >
      {props.name}
    </BasicMenuItemStyled>
  );
};

export default BasicMenuItem;
