import SubMenuItem from 'components/atoms/header_menu/SubMenuItem';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { heights, zIndexes } from 'styles/variables';
import { HeaderSubMenuItemType } from 'types/headerMenuTypes';

const HeaderSubMenuStyled = styled.nav`
  .header-sub-menu {
    width: 100%;
    background-color: #f0f0f0;
    display: block;
    left: 0;
    top: 0;
    opacity: 0.9;
    position: fixed;
    z-index: ${zIndexes.subMenu};

    &__container {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  @keyframes showSubMenu {
    0% {
      top: 0px;
    }
    100% {
      top: ${heights.headerMenu};
    }
  }

  @keyframes hideSubMenu {
    0% {
      top: ${heights.headerMenu};
    }
    100% {
      top: 0px;
    }
  }

  .show-sub-menu {
    animation-name: showSubMenu;
    animation-duration: 0.3s;
    animation-timing-function: ease;
    animation-fill-mode: forwards;
  }

  .hide-sub-menu {
    animation-name: hideSubMenu;
    animation-duration: 0.3s;
    animation-timing-function: ease;
    animation-fill-mode: forwards;
  }

  .init-sub-menu {
    animation-delay: -0.3s;
  }
`;

type SubMenuType = {
  subMenuOpened: boolean;
  subMenuData: HeaderSubMenuItemType[];
};

const HeaderSubMenu = (props: SubMenuType) => {
  const [notClicked, setNotClicked] = useState(true);

  useEffect(() => {
    if (props.subMenuOpened && notClicked) {
      setNotClicked(false);
    }
  }, [props.subMenuOpened]);

  const showSubMenuClass = () => {
    if (notClicked) {
      return '';
    }

    return props.subMenuOpened ? 'show-sub-menu' : 'hide-sub-menu';
  };

  return (
    <HeaderSubMenuStyled>
      <div className={['header-sub-menu', showSubMenuClass()].join(' ')}>
        <div className='header-sub-menu__container'>
          {props.subMenuData.map((item, i: number) => (
            <SubMenuItem key={i} name={item.name} path={item.path} />
          ))}
        </div>
      </div>
    </HeaderSubMenuStyled>
  );
};

export default HeaderSubMenu;
