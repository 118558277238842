import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { colors } from 'styles/variables';

const SubMenuItemStyled = styled.span`
  &.sub-menu-item {
    align-items: center;
    border-bottom: 3px solid rgba(0, 0, 0, 0);
    color: #333;
    cursor: pointer;
    font-size: 14px;
    font-weight: 200;
    margin: 0 30px;
    padding: 15px 20px 10px 20px;
    text-decoration: none;
    white-space: nowrap;

    &:hover {
      border-bottom: 3px solid ${colors.primary};
      color: ${colors.primary};
      font-weight: 400;
    }

    &.current-sub-menu {
      border-bottom: 3px solid ${colors.primary};
      color: ${colors.primary};
      font-weight: 400;
    }
  }
`;

interface SubMenuItemInterface {
  name: string;
  path: string;
};

const SubMenuItem = (props: SubMenuItemInterface) => {
  const [current, setCurrent] = useState(false);
  const router = useRouter();

  useEffect(() => {
    setCurrent(window.location.pathname === props.path);
  }, [window.location.pathname]);

  return (
    <SubMenuItemStyled
      className={['sub-menu-item', current ? 'current-sub-menu' : ''].join(' ')}
      onClick={() => router.push(props.path)}
    >
      {props.name}
    </SubMenuItemStyled>
  );
};

export default SubMenuItem;
